export enum SurveyOneOption {
    ChangeableSentence = 'Changeable Sentence',
    SpecificCollections = 'Specific Collections',
    MobileApp = 'Mobile App',
    Other = 'Other',
}

export enum MobileOperatingSystem {
    Android = 'Android',
    iOS = 'iOS',
}

export type SurveyOneResponse = {
    selected: SurveyOneOption,
    info: MobileOperatingSystem | string | null,
}

// export enum SurveyTwoOption {
//     ExcludeWords = 'Exclude Words',
//     OwnWords = 'Own Words',
// }

// export type SurveyTwoResponse = SurveyTwoOption[] // if selectToRank question is next

export type SurveyRecord = {
    response: SurveyOneResponse,
    daysSignedUp: number | null,
    isPayingCustomer: boolean
}