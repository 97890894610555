<script lang="ts">
    import {onMount} from 'svelte';
    import 'survey-core/defaultV2.min.css';
    import {type ServerValidateQuestionsEvent, type SurveyModel} from 'survey-core';
    import {MobileOperatingSystem, SurveyOneOption, type SurveyOneResponse} from '$lib/classes/_types/survey';
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let saveResponse: (result: SurveyOneResponse) => Promise<void>
    export let surveyResponse: SurveyOneResponse | undefined = undefined

    enum Progress {
        FillingOut,
        Saving,
        Done,
    }

    export let skippable: boolean

    let survey: SurveyModel
    let surveyElem: HTMLElement
    let progress = surveyResponse ? Progress.Done : Progress.FillingOut

    const choices = [
        {
            value: SurveyOneOption.ChangeableSentence,
            customProperty: {
                description: 'The option to change to a different sentence on the flashcard when learning to increase variety.'
            }
        },
        {
            value: SurveyOneOption.MobileApp,
            customProperty: {
                description: 'Access the same features through a dedicated mobile application for convenient on-the-go learning.'
            }
        },
        {
            value: SurveyOneOption.SpecificCollections,
            customProperty: {
                description: 'Filter the main word list for specific collections like \'Time\', \'Food & Drink\', \'Clothes and Accessories\', \'Nature\', \'Travel\', ...'
            }
        },
        // {
        //     value: 'App',
        //     customProperty: {
        //         description: '...?'
        //     }
        // },
        // {
        //     value: 'Exclude words',
        //     customProperty: {
        //         description: 'Mark words as \'excluded\' to prevent them from being added in bulk mode or to filter.'
        //     }
        // },
        // {
        //     value: 'Own words',
        //     customProperty: {
        //         description: 'Add own words to your deck.'
        //     }
        // }
    ]

    const json = {
        elements: [
            // {
            //     type: 'ranking',
            //     name: 'features',
            //     choicesOrder: 'random',
            //     choices: choices,
            //     title: '1. Select up to three items you consider most important. (Explanations below.) \n2. Rank the selected items from the most important to the least',
            //     isRequired: true,
            //     selectToRankEnabled: true,
            //     selectToRankAreasLayout: 'vertical',
            //     minSelectedChoices: 1,
            //     maxSelectedChoices: 3,
            // },
            {
                type: 'radiogroup',
                name: 'next',
                title: 'Which addition would you appreciate most? (Description below)',
                isRequired: true,
                // choicesOrder: 'random',
                // showNoneItem: true,
                showOtherItem: true,
                colCount: 1,
                choices: choices,
                separateSpecialChoices: true, // separates 'other' and 'none' from the rest
                // showClearButton: true,
            },
            {
                type: 'radiogroup',
                name: 'operatingSystem',
                title: 'Which operating system do you prefer?',
                isRequired: true,
                visibleIf: `{next} contains \'${SurveyOneOption.MobileApp}\'`,
                choices: [
                    {
                        value: MobileOperatingSystem.Android,
                        text: 'Android',
                    },
                    {
                        value: MobileOperatingSystem.iOS,
                        text: 'iOS (iPhone)',
                    }
                ]
            },
        ],
        showQuestionNumbers: false,
        showCompletedPage: false,
    };

    onMount(async () => {
        if (progress === Progress.Done) return

        // NOTE dynamic imports important - won't work with static ones
        await import('survey-js-ui');
        const {Model} = await import('survey-core');
        const {DoubleBorderLight} = await import('survey-core/themes');

        survey = new Model(json);
        survey.applyTheme(DoubleBorderLight);
        survey.completeText = 'Submit';
        // survey.css = customCss

        if (skippable) {
            survey.addNavigationItem({
                id: 'skip-survey',
                title: 'Skip Survey',
                visibleIndex: 51, // The "Complete" button has the visibleIndex 50.
                action: () => {
                    dispatch('skip')
                }
            });
        }

        // NOTE use onServerValidateQuestions instead of onComplete because that enables error handling and preventing submission
        survey.onServerValidateQuestions.add(handleComplete);

        survey.render(surveyElem);
    })

    async function handleComplete(sender: SurveyModel, options: ServerValidateQuestionsEvent) {
        progress = Progress.Saving
        const data = sender.data
        if (data.next === 'other') data.next = 'Other' // for capitalised naming in enum - didn't find an option to rename in SurveyJS
        const selected = data.next as SurveyOneOption
        surveyResponse = {
            selected: selected,
            info:
                selected === SurveyOneOption.MobileApp ? data.operatingSystem as MobileOperatingSystem
                    : selected === SurveyOneOption.Other ? data['next-Comment'] as string
                        : null
        }
        try {
            await saveResponse(surveyResponse)
            progress = Progress.Done
        } catch (error) {
            console.error(`ERROR saving survey result - ${JSON.stringify(surveyResponse)}`, error)
            options.errors['next'] = 'We\'re sorry, but there was an error saving your survey response. Please try again and contact support@vocabeo.com if the problem persists.';
            progress = Progress.FillingOut
        } finally {
            options.complete()
        }
    }
</script>

<!--NOTE Handle visibility via classes so that survey element is not destroyed with showing saving screen-->

<div class="filling-out"
     class:visible={progress === Progress.FillingOut}
>
    <div id="survey-element"
         class="survey-container"
         bind:this={surveyElem}
    ></div>
    <hr>
    <div class="choices">
        {#each choices as choice}
            <div class="choice">
                <b>{choice.value}</b> → {choice.customProperty.description}
            </div>
        {/each}
    </div>
</div>

<div class="saving"
     class:visible={progress === Progress.Saving}
>
    <p>Saving...</p>
</div>

<div class="done"
     class:visible={progress === Progress.Done}
>
    <p>
        {#if surveyResponse}
            You chose: {surveyResponse.selected} {surveyResponse.info ? `- ${surveyResponse.info}` : ''}
        {/if}
    </p>
    <p>
        <b>Thank you for your feedback!</b> 🙌
    </p>
    <!-- close button -->
    <slot/>
</div>

<!--suppress CssUnusedSymbol -->
<style>
    .filling-out,
    .saving,
    .done {
        display: none;
    }

    .visible {
        display: block;
    }

    p {
        margin: 0 0 var(--space-md) 0;
    }

    :global(.sd-root-modern, .sd-body, .sd-action-bar ) {
        background-color: transparent !important;
        --sjs-primary-backcolor: var(--color-Verb) !important;
        --sjs-special-red: var(--color-error);
        --sjs-special-red-light: var(--color-error-light);
    }

    :global(.sd-page, .sd-body) {
        padding: 0 !important;
    }

    :global(.sd-action-bar) {
        padding-inline: 0 !important;
        padding-bottom: 0 !important;
    }

    :global(.sd-btn--action) {
        --sjs-primary-backcolor: var(--color-Verb) !important;
    }

    :global(.sd-btn--action:hover) {
        --sjs-primary-backcolor-dark: #b87e14 !important;
    }

    hr {
        margin-block: var(--space-lg);
    }

    .choices {
        display: grid;
        gap: var(--space-md);
    }

    @media (max-width: 1000px) {
        hr {
            margin-block: var(--space-md);
        }

        .choices {
            font-size: .95rem;
        }
    }
</style>