<script lang="ts">
    import Survey from '$lib/components/next-feature/survey.svelte';
    import LoadingDots from '$lib/components/assets/LoadingDots.svelte';
    import type {SurveyOneResponse, SurveyRecord} from '$lib/classes/_types/survey';
    import type {Auth} from '$lib/classes/services/auth';
    import type {Database} from '$lib/classes/services/database';
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let auth: Auth
    export let database: Database
    export let isPayingCustomer: boolean
    export let closeable = false
    export let skippeable = false

    async function loadSurveyRecord(): Promise<SurveyRecord | null> {
        const uid = auth.getUid()
        if (!uid) return null
        try {
            return await database.getSurveyRecord(uid)
        } catch (error) {
            throw new Error('ERROR loading survey record from db', {cause: error})
        }
    }

    async function saveSurveyRecord(response: SurveyOneResponse) {
        const uid = auth.getUid()
        if (!uid) throw new Error('No uid')
        const daysSignedUp = auth.getDaysSignedUp()
        await database.setSurveyRecord(uid, {
            response: response,
            daysSignedUp,
            isPayingCustomer,
        })
    }
</script>

<h2>What should come next? 🤔</h2>
<!--    <h2>What should be added next?</h2>-->
{#await loadSurveyRecord()}
    <LoadingDots
            width="60"
            fill="var(--color-Verb)"
    />
{:then surveyRecord}
    <Survey
            saveResponse={saveSurveyRecord}
            surveyResponse={surveyRecord?.response}
            skippable={skippeable}
            on:skip
    >
        {#if closeable}
            <button class="button-primary"
                    on:click={() => dispatch('close')}
            >
                Close
            </button>
        {/if}
    </Survey>
{:catch _}
    <p>There has been a problem starting the survey. Please try again
        or contact support@vocabeo.com if the problem persists.</p>
{/await}

<style>
    h2 {
        font-size: 1.4rem;
        margin: 0 0 var(--space-md);
    }

    @media (max-width: 1000px) {
        h2 {
            font-size: 1.2rem;
        }
    }
</style>
